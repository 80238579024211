.container {
  background: linear-gradient(
    184deg,
    rgba(238, 246, 252, 1) 0%,
    rgba(216, 222, 228, 1) 100%
  );
  padding: 10px 30px;
  box-shadow: 5px 5px 13px 0px rgba(204, 211, 216, 0.9),
    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
    -5px 5px 10px 0px rgba(204, 211, 216, 0.2);
}

.navList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.navItem {
  list-style-type: none;
}

.navItemText {
  color: #31383d;
}

.navItemTextActive {
  color: #4b68fa;
}

.navItemIconActive {
  stroke: #4b68fa;
}

.navLink {
  text-decoration: none;
}
