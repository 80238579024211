.dark {
  display: block;
  padding: 15px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(125, 146, 250, 1) 0%,
    rgba(75, 104, 250, 1) 100%
  );
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  border: none;
  outline: none;
  width: 100%;
}

.light {
  display: block;
  padding: 15px;
  border-radius: 10px;
  background-color: #bcc2e3;
  text-align: center;
  color: #4b68fa;
  font-weight: 600;
  border: none;
  outline: none;
  width: 100%;
}
