.icon {
  background: linear-gradient(
    180deg,
    rgba(125, 146, 250, 1) 0%,
    rgba(75, 104, 250, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.icon img {
  display: block;
  margin: 0;
  padding: 0;
}
