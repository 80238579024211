.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* Выстроить элементы в ряд */
  justify-content: center; /* Выровнять элементы по центру */
  background-color: #424242; /* Темный фон всего списка */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Добавим немного тени под всей панелью */
}

.navItem {
  margin: 10px 15px; /* Расстояние между элементами увеличено */
  
}

.navLink {
  display: block;
  padding: 15px 25px; /* Увеличенные паддинги для большей кнопки */
  text-decoration: none; /* Убрать подчеркивание текста */
  color: #fff; /* Белый цвет текста для контраста на темном фоне */
  background-color: #556cd6; /* Средне-серый фон кнопок */
  border-radius: 8px; /* Увеличенное скругление углов */
  box-shadow: 2px 2px 10px rgba(134, 133, 133, 0.151); /* Увеличенная тень для более глубокого эффекта */
  transition: all 0.3s ease; /* Плавный переход всех изменений */
}

.navLink:hover, .navLink:focus {
  color: #000000; /* Желтый цвет текста при наведении */
  background-color: #757575; /* Более темный серый фон при наведении */
  transform: translate(-2px, -2px); /* Небольшое смещение для эффекта поднятия */
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.4); /* Усиленная тень при наведении */
}
