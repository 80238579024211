.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.container label {
  display: block;
  margin-bottom: 10px;
  align-self: flex-start;
  color: #31383d;
  font-size: 13px;
}

.container input,
.container select {
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #eef6fc;
  box-shadow: 5px 5px 13px 0px rgba(204, 211, 216, 0.9),
    -5px -5px 10px 0px rgba(250, 255, 255, 0.9),
    5px -5px 10px 0px rgba(204, 211, 216, 0.2),
    -5px 5px 10px 0px rgba(204, 211, 216, 0.2);
  padding: 15px;
  width: 100%;
}
